<template>
    <div class="w3-theme-l1" style="">
        <div class="w3-container" style="padding-top:10px">
            <Toolbar  
                :click-add="function(){}"
				:statusAdd=false
				:statusExport=false
				:statusPrint=false
                :click-refresh="loadData"
                :click-print="function(){}"
                :set-filter="setFilter"
                :field-find="filters.fields"
                :data   = "recs"
                :fields = "json_fields"
                type    = "xls"
                name    = "pengaduan.xls"
                help = ""
            />
            <div class="w3-row">
                <div class="w3-col l7 m7 s12">
                    <div @mouseover="grid = true" @mouseleave="grid = false">
                        <vue-scrolling-table v-bind:style="'height:'+  (screenHeight-149) +'px;'" class="w3-table w3-striped w3-bordered w3-white w3-small freezeFirstColumn  freezeLasttColumn" :include-footer="true" :dead-area-color="'w3-theme'" :scroll-horizontal="true" id="grid">
                            <template slot="thead">
                                <tr class="w3-border-right">
                                    <th style="min-width:50px;" class="w3-light-grey w3-border-0">
                                        No
                                    </th>
                                    <th v-for="fl in filterByShow" :key="fl.key" :style="'min-width:' + fl.width+
                                    (fl['sort']['status']?';cursor:pointer':'')"
                                        class="w3-light-grey w3-border-0" >
                                        <div v-on:click="setFilterTable(fl)">{{fl.name}}
                                            <span v-if="fl['sort']['status']">
                                                <i class="fa"
                                                    :class="fl['sort']['asc']=='asc'?'fa-sort-asc':(fl['sort']['asc']=='desc'?'fa-sort-desc':'fa-arrows-v')"></i>
                                            </span>
                                        </div>
                                    </th>
                                </tr>
                            </template>
                            <template slot="tbody">
                                <tr :id="'grid-' + index" @mouseover="dtl['pilih']=true" @mouseleave="dtl['pilih']=false" v-for="(dtl, index) in recs"  style="cursor: pointer;" :class="(dtl.pilih==true?'bayangan w3-theme-l4':(index == selectedRow ?'bayangan w3-theme-l4':'w3-opacity-min'))"  :key="dtl.pengaduan_code" class="w3-text-black">
                                    <td style="min-width:50px;" class="w3-light-grey w3-border-0 w3-border-right">
                                        <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>{{index+((pages.halaman-1)*pages.batas)+1}}
                                    </td>
                                    <td v-on:click="selectedRow=index" :style="'min-width:'+findObjFind('width','pengaduan_nama')" class=" w3-border-0 w3-border-right">
                                        <div v-html="setTextFind(dtl.pengaduan_nama,'pengaduan_nama')"></div>
                                        <div v-html="setTextFind(dtl.pengaduan_alamat,'pengaduan_alamat')"></div>
                                    </td>
                                    <td v-on:click="selectedRow=index" :style="'min-width:'+findObjFind('width','pengaduan_isi')" class=" w3-border-0 w3-border-right">
                                        <span v-html="setTextFind(dtl.pengaduan_isi,'pengaduan_isi')"></span>
                                    </td>
                                    <td v-on:click="selectedRow=index" :style="'min-width:'+findObjFind('width','date_edit')" class=" w3-border-0 w3-border-right">
                                        {{dtl.date_edit | moment("DD-MM-yyyy hh:mm:ss")}}
                                    </td>
                                    <td style="padding: 3px 0px 0px 10px;" :style="'min-width:' + findObjFind('width','pengaduan_status')" class=" w3-border-0 w3-center w3-border-right w3-border-right" >
                                        <div v-on:click="setKlikRow(index, dtl)" style="position: relative;" :style="'min-width:' + findObjFind('width','pengaduan_status')">
                                            <span v-html="setStatus(dtl.pengaduan_status)"></span>
                                            <div v-show="dtl['pilih']==true && dtl.pengaduan_status=='T'" style="position: absolute; top: -5px; left: -5px; text-align:center;" class="w3-opacity-off">
                                                <!-- <button style="padding: 5px 7px 5px 7px; margin-right:5px;" class="w3-btn w3-circle w3-tiny w3-theme-d3" v-on:click="showEdit(dtl, index)"><i class="fa fa-check w3-large"></i></button> -->
                                                <button style="padding: 5px 7px 5px 7px; margin-right:5px;" class="w3-btn w3-circle w3-tiny w3-red" v-on:click="showDelete(dtl, index)"><i class="fa fa-trash-o w3-large"></i></button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            <template slot="tfoot">
                                <Paging class="w3-center "
                                    :click-handler="loadPage"
                                    :prev-text="'Prev'"
                                    :next-text="'Next'"
                                    :container-class="'w3-bar'"
                                    :page-count="pages.ttlhalaman"
                                    :page-range="10"
                                    :margin-pages="2"
                                    :value="pages.halaman" />
                            </template>
                        </vue-scrolling-table>
                    </div>
                </div>
                <div class="w3-col l5 m5 s12" style="padding-left:5px; position: relative;">
                    <label for="isi" style="position: absolute; left:15px; top:5px;" class="w3-text-blue">Jawaban</label>
                    <textarea v-bind:readonly="selectedRow==-1" @blur="saveJawaban" v-model="recJawab.pengaduan_isi" name="isi" id="isi" cols="30" rows="10" class="w3-input w3-small w3-border w3-card-4" v-bind:style="'height:'+  (screenHeight-149) +'px;'" style="padding-top:25px;"></textarea>
                </div>
            </div>
        </div>
		<div id="id01" class="w3-modal" >
			<vue-draggable-resizable :y="50" :drag-handle="'.drag'" style="width:100%;">
				<div class="w3-modal-content w3-animate-zoom w3-card-4" style="max-width:500px;">   
					<header class="w3-container w3-theme-d3 drag" style="cursor:move;"> 
						<span onclick="document.getElementById('id01').style.display='none'" 
						class="w3-button w3-display-topright">&times;</span>
						<h6><i class="fa fa-windows"></i> Proses Jam</h6>
					</header>
					<div class="w3-container w3-padding w3-theme-l4 ">
						<form method="post" id="frmPengaduan" v-on:submit.prevent="saveData" action="#" autocomplete="off">
						    <div class="w3-row" style="margin-top:5px;">
						        <div class="w3-col l12 m12 s12">
						            <input type="text" id="pengaduan_isi" v-model="recJawab.pengaduan_isi" class="w3-input w3-small w3-round w3-border" :placeholder="findObjFind('name','pengaduan_isi')" />
						        </div>
						        
						    </div>
						</form>
					</div>
					<footer class="w3-container w3-theme-l1">
					    <h6 class="w3-right">
					        <button form="frmPengaduan" type="submit" class="w3-btn w3-small w3-theme-d5 w3-round" style="margin-right:5px;"><i class="fa fa-save"></i> Save</button>
					        <button onclick="document.getElementById('id01').style.display='none'" class="w3-btn w3-small w3-red w3-round"><i class="fa fa-close"></i> Close</button>
					    </h6>
					</footer>
				</div>
			</vue-draggable-resizable>
		</div>
    </div>
</template>
<script>
/* eslint-disable */
import moment from "moment";
export default {
    head: {
        title: {
          inner: 'Pengaduan'
        },
    },
    name: 'pengaduan',
    components: {},
    data() {
        return {
            err:'',
            grid : false,
            screenWidth : 0,
            screenHeight : 0,
            tableHeight : 0,
            selectedRow : -1,
            errorMessage : {pengaduan_name:'',pengaduan_singkatan:''},
            fieldRules : [],
            recs : [],
            rec : {pengaduan_code:'', pengaduan_name : '', pengaduan_singkatan:'', pengaduan_status:'T'},
            recJawab : {pengaduan_code:'', pengaduan_name : '', pengaduan_singkatan:'', pengaduan_status:'T', pengaduan_isi: ""},
            pages : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, autoadd :'F'},
            filters : {
                operator : 'AND',
                fields: [
                    {fieldFind:'', key : 'pengaduan_code', type: 'text', name : 'Kode', filter : false, width : '', grid : false},
                    {fieldFind:'', key : 'pengaduan_nama', type: 'text', name : 'Nama', filter : true, width : '200px', grid : true, sort: {status: true, asc: '-'}},
                    {fieldFind:'', key : 'pengaduan_alamat', type: 'text', name : 'alamat', filter : true, width : '', grid : false, sort: {status: true, asc: '-'}},
                    {fieldFind:'', key : 'pengaduan_hp', type: 'text', name : 'HP', filter : false, width : '', grid : false, sort: {status: true, asc: '-'}},
                    {fieldFind:'', key : 'pengaduan_isi', type: 'text', name : 'Isi', filter : true, width : '400px', grid : true, sort: {status: true, asc: '-'}},
                    {fieldFind:'', key : 'user_entry', type: 'text', name : 'User Entry', filter : false, width : '', grid : false},
                    {fieldFind:'', key : 'date_entry', type: 'text', name : 'Date Entry', filter : false, width : '', grid : false},
                    {fieldFind:'', key: "date_edit",type: "text",name: "Date Entry",filter: false,width: "150px",grid: true, sort: {status: true, asc: 'desc'}},
                    {fieldFind:'', key: "user_edit",type: "text",name: "Entry",filter: false,width: "",grid: false, sort: {status: false, asc: '-'}},
                    {fieldFind:'', key : 'pengaduan_status', type: 'select', name : 'Status', filter : true, width : '80px', grid : true, valueFind: [{key:'T',label:'Aktif'},{key:'F',label:'Tidak Aktif'}], sort: {status: false, asc: '-'}},
                ],
                find : [],
                order: ""
            },
            json_fields : {'Kode' : 'pengaduan_code','Nama' : 'pengaduan_name'}, 
            isi: ""           
        };
    },
    methods : {
        findObjFind(key, val){
            return this.findObj(this.filters.fields,'key',val)[key];
        },
        setTextFind(txt,field){
            let fieldvalue = "";
            let ketemu = false;
            const self = this;
            if(self.filters.find!==undefined )
                if ( self.filters.find.length >0 ) {
                    self.filters.find.map(function(value){
                        if(value.field ===field){
                            ketemu = true;
                        }
                        if(fieldvalue!=='null')
                            fieldvalue += ((fieldvalue!=='')?';':'')+ value.fieldValue ;
                        else
                            fieldvalue += ((fieldvalue!=='null')?';':'')+ value.fieldValue ;
                    });
                }
            if(ketemu){
                return self.sethighlight(txt,fieldvalue);
            }else{
                return txt;
            }
        },
        loadPage (pageNum){
            this.pages.halaman = pageNum;
            this.loadData();
        },
        setFilter(filter, operator){
            this.filters.find = filter;
            this.filters.operator = operator;
            this.loadData();
        },
        loadData(){
            const self = this;
            let vparams = {
                operator: self.filters.find.length > 0 ? self.filters.operator : "AND",
                kondisi: "=",
                field: "pengaduan_up",
                fieldvalue: "-",
                limit: self.pages.batas,
                offset: eval(self.pages.batas + "*(" + self.pages.halaman + "-1)"),
                order: self.filters.order
            };

            if (self.filters.find.length > 0) {
                self.filters.find.map(function(value) {
                    vparams.kondisi += (vparams.kondisi !== "" ? ";" : "") + value.kondisi;
                    vparams.field += (vparams.field !== "" ? ";" : "") + value.field;
                    if (vparams.fieldvalue !== "null")
                        vparams.fieldvalue += (vparams.fieldvalue !== "" ? ";" : "") + value.fieldValue;
                    else
                        vparams.fieldvalue += (vparams.fieldvalue !== "null" ? ";" : "") + value.fieldValue;
                });
            }
            self.apiPost('/antrian/pengaduan/search',vparams,function(response){
                if(response.success==true){
                    self.selectedRow = -1;
                    self.recs = response.rec;
                    self.pages.ttlrec = parseFloat(response.ttlrec);
                    self.pages.ttlhalaman = Math.ceil(parseFloat(response.ttlrec)/self.pages.batas);
                    self.fieldRules = response.rules;
                }else{
                    console.log('Gagal');
                }
            }, function(err){
				console.log(err);
			});
        },
        showPrint() {
            this.$toast.info('Under Production');
        },
        setStatus(dt) {
            if (dt === "T") {
                return "<div class='w3-green w3-center w3-round' style='width:50px;margin-top:5px;'>Active</div>";
            } else {
                return "<div class='w3-red w3-center w3-round' style='width:50px;margin-top:5px;'>In Active<div>";
            }
        },
        setFilterTable(dt){
            if(!this.setFilterHeader(dt, this.filters)) {
                this.loadData();
            }
        },
        loadJawaban(){
            const self = this;
            self.apiPost("/antrian/pengaduan/search", {
                operator: "AND",
                kondisi: "=",
                field: "pengaduan_up",
                fieldvalue: self.rec.pengaduan_code,
                limit: 1,
                offset: 0
            }, async function(res){
                // console.log(res);
                if (res.ttlrec>0){
                    self.recJawab = res.rec[0];
                    self.recJawab["pengaduan_code_old"] = self.recJawab["pengaduan_code"];
                }else{
                    let key = await self.getCode();
                    self.recJawab = {
                        "event":"save",
                        "pengaduan_code": key,
                        "pengaduan_code_old": "",
                        "pengaduan_nama": "Admin",
                        "pengaduan_alamat": "-",
                        "pengaduan_hp": "-",
                        "pengaduan_isi": "",
                        "pengaduan_up": self.rec.pengaduan_code,
                        "pengaduan_status": "T"
                    };
                }
                console.log(self.recJawab);
            }, function(err){
                console.log(err);
            });
        },
        saveJawaban(){
            const self = this;
            if (self.recJawab.pengaduan_isi==""){
                return false;
            }
            self.apiPublicPost('/antrian/pengaduan/save',self.recJawab,function(response){
                if(response.success==true){
                    self.$toast.success('Data Sudah tersimpan');
                    if (self.recJawab["pengaduan_code_old"]=="") {
						self.recJawab["pengaduan_code"]== response.kode;
						self.recJawab["pengaduan_code_old"]== response.kode;
                    }
                }else{
                    self.$toast.error(response.msg);
                }
            }, function(err){
				console.log(err);
			});
        },
        setStatus(dt) {
            if (dt === "T") {
                return "<div class='w3-green w3-center w3-round' style='width:50px;margin-top:5px;'>Active</div>";
            } else {
                return "<div class='w3-red w3-center w3-round' style='width:50px;margin-top:5px;'>In Active<div>";
            }
        },
        showDelete(dt){
            const self = this;
            swal({
                title: "Yakin Ingin Menghapus Data ..!",
                text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Ya, Hapus Data",
                cancelButtonText: "Tidak, Batal Hapus",
                closeOnConfirm: true,
                closeOnCancel: true
            },function(isConfirm){
                if (isConfirm) {
                    let vparams = {pengaduan_code_old : dt.pengaduan_code};
                    self.apiPublicPost('/antrian/pengaduan/delete' ,vparams,function(response){
                        if(response.success==true){
                            self.$toast.success('Data Sudah terhapus');
                            self.$delete(self.recs,self.selectedRow);
                        }else{
                            self.$toast.error(response.msg);
                        }
                    });                    
                }
            });
        }
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;                
            });
        },
    },
    filters: {
        toRp (value) {
            return `${Intl.NumberFormat('de-DE').format(value)}`
        }
    },
    watch: {
        'selectedRow': function(baru){
            this.rec = Object.assign({}, this.recs[baru]);
            this.loadJawaban();
        }
    },
    mounted(){
        const self = this;
        this.pages = {halaman: 1,batas: this.perPage,ttlhalaman: 0,ttlrec: 0,autoadd: "T"};
        this.screenWidth = screen.width;
        this.screenHeight = window.innerHeight;
        this.eventGrid(self, 'grid', 'selectedRow', 'recs');
        this.getGridColumnmAutoWidth('grid',self.filters.fields, false);
        this.setFilterHeaders(this.filters);
        this.loadData();
    }
};
</script>